<template>
  <div class="content-editable-wrapper d-flex align-center justify-start">
    <contenteditable
      v-bind="$attrs"
      class="content-editable"
      tag="div"
      :contenteditable="editable"
      v-model="localValue"
    ></contenteditable>
    <v-icon color="#e0e0e0" class="edit-icon" x-small v-if="showEdit">
      mdi-pencil
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'ContentEditable',
  inheritAttrs: false,
  props: {
    showEdit: { type: Boolean, default: true },
    editable: { type: Boolean, default: true },
    value: { type: String, default: '' }
  },
  data() {
    return {
      localValue: ''
    }
  },
  watch: {
    value: {
      handler: function (val) {
        this.localValue = val
      },
      immediate: true
    },
    localValue: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.content-editable-wrapper {
  .content-editable {
    min-width: 10px;
    min-height: 10px;
    margin: auto 2px;
    cursor: text !important;
  }
  .edit-icon {
    left: 2px;
    position: relative;
    top: -5px;
    right: 0;
  }
}
</style>
